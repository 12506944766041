var githubDarkDimmed = Object.freeze({
  "colors": {
    "activityBar.activeBorder": "#ec775c",
    "activityBar.background": "#22272e",
    "activityBar.border": "#444c56",
    "activityBar.foreground": "#adbac7",
    "activityBar.inactiveForeground": "#768390",
    "activityBarBadge.background": "#316dca",
    "activityBarBadge.foreground": "#cdd9e5",
    "badge.background": "#316dca",
    "badge.foreground": "#cdd9e5",
    "breadcrumb.activeSelectionForeground": "#768390",
    "breadcrumb.focusForeground": "#adbac7",
    "breadcrumb.foreground": "#768390",
    "breadcrumbPicker.background": "#2d333b",
    "button.background": "#347d39",
    "button.foreground": "#ffffff",
    "button.hoverBackground": "#46954a",
    "button.secondaryBackground": "#3d444d",
    "button.secondaryForeground": "#adbac7",
    "button.secondaryHoverBackground": "#444c56",
    "checkbox.background": "#2d333b",
    "checkbox.border": "#444c56",
    "debugConsole.errorForeground": "#ff938a",
    "debugConsole.infoForeground": "#768390",
    "debugConsole.sourceForeground": "#daaa3f",
    "debugConsole.warningForeground": "#c69026",
    "debugConsoleInputIcon.foreground": "#b083f0",
    "debugIcon.breakpointForeground": "#e5534b",
    "debugTokenExpression.boolean": "#6bc46d",
    "debugTokenExpression.error": "#ff938a",
    "debugTokenExpression.name": "#6cb6ff",
    "debugTokenExpression.number": "#6bc46d",
    "debugTokenExpression.string": "#96d0ff",
    "debugTokenExpression.value": "#96d0ff",
    "debugToolBar.background": "#2d333b",
    "descriptionForeground": "#768390",
    "diffEditor.insertedLineBackground": "#347d3926",
    "diffEditor.insertedTextBackground": "#57ab5a4d",
    "diffEditor.removedLineBackground": "#c93c3726",
    "diffEditor.removedTextBackground": "#f470674d",
    "dropdown.background": "#2d333b",
    "dropdown.border": "#444c56",
    "dropdown.foreground": "#adbac7",
    "dropdown.listBackground": "#2d333b",
    "editor.background": "#22272e",
    "editor.findMatchBackground": "#966600",
    "editor.findMatchHighlightBackground": "#eac55f80",
    "editor.focusedStackFrameHighlightBackground": "#46954a66",
    "editor.foldBackground": "#636e7b1a",
    "editor.foreground": "#adbac7",
    "editor.lineHighlightBackground": "#636e7b1a",
    "editor.linkedEditingBackground": "#539bf512",
    "editor.selectionHighlightBackground": "#57ab5a40",
    "editor.stackFrameHighlightBackground": "#ae7c1466",
    "editor.wordHighlightBackground": "#636e7b80",
    "editor.wordHighlightBorder": "#636e7b99",
    "editor.wordHighlightStrongBackground": "#636e7b4d",
    "editor.wordHighlightStrongBorder": "#636e7b99",
    "editorBracketHighlight.foreground1": "#6cb6ff",
    "editorBracketHighlight.foreground2": "#6bc46d",
    "editorBracketHighlight.foreground3": "#daaa3f",
    "editorBracketHighlight.foreground4": "#ff938a",
    "editorBracketHighlight.foreground5": "#fc8dc7",
    "editorBracketHighlight.foreground6": "#dcbdfb",
    "editorBracketHighlight.unexpectedBracket.foreground": "#768390",
    "editorBracketMatch.background": "#57ab5a40",
    "editorBracketMatch.border": "#57ab5a99",
    "editorCursor.foreground": "#539bf5",
    "editorGroup.border": "#444c56",
    "editorGroupHeader.tabsBackground": "#1c2128",
    "editorGroupHeader.tabsBorder": "#444c56",
    "editorGutter.addedBackground": "#46954a66",
    "editorGutter.deletedBackground": "#e5534b66",
    "editorGutter.modifiedBackground": "#ae7c1466",
    "editorIndentGuide.activeBackground": "#adbac73d",
    "editorIndentGuide.background": "#adbac71f",
    "editorInlayHint.background": "#76839033",
    "editorInlayHint.foreground": "#768390",
    "editorInlayHint.paramBackground": "#76839033",
    "editorInlayHint.paramForeground": "#768390",
    "editorInlayHint.typeBackground": "#76839033",
    "editorInlayHint.typeForeground": "#768390",
    "editorLineNumber.activeForeground": "#adbac7",
    "editorLineNumber.foreground": "#636e7b",
    "editorOverviewRuler.border": "#1c2128",
    "editorWhitespace.foreground": "#545d68",
    "editorWidget.background": "#2d333b",
    "errorForeground": "#e5534b",
    "focusBorder": "#316dca",
    "foreground": "#adbac7",
    "gitDecoration.addedResourceForeground": "#57ab5a",
    "gitDecoration.conflictingResourceForeground": "#cc6b2c",
    "gitDecoration.deletedResourceForeground": "#e5534b",
    "gitDecoration.ignoredResourceForeground": "#636e7b",
    "gitDecoration.modifiedResourceForeground": "#c69026",
    "gitDecoration.submoduleResourceForeground": "#768390",
    "gitDecoration.untrackedResourceForeground": "#57ab5a",
    "icon.foreground": "#768390",
    "input.background": "#22272e",
    "input.border": "#444c56",
    "input.foreground": "#adbac7",
    "input.placeholderForeground": "#636e7b",
    "keybindingLabel.foreground": "#adbac7",
    "list.activeSelectionBackground": "#636e7b66",
    "list.activeSelectionForeground": "#adbac7",
    "list.focusBackground": "#4184e426",
    "list.focusForeground": "#adbac7",
    "list.highlightForeground": "#539bf5",
    "list.hoverBackground": "#636e7b1a",
    "list.hoverForeground": "#adbac7",
    "list.inactiveFocusBackground": "#4184e426",
    "list.inactiveSelectionBackground": "#636e7b66",
    "list.inactiveSelectionForeground": "#adbac7",
    "minimapSlider.activeBackground": "#76839047",
    "minimapSlider.background": "#76839033",
    "minimapSlider.hoverBackground": "#7683903d",
    "notificationCenterHeader.background": "#2d333b",
    "notificationCenterHeader.foreground": "#768390",
    "notifications.background": "#2d333b",
    "notifications.border": "#444c56",
    "notifications.foreground": "#adbac7",
    "notificationsErrorIcon.foreground": "#e5534b",
    "notificationsInfoIcon.foreground": "#539bf5",
    "notificationsWarningIcon.foreground": "#c69026",
    "panel.background": "#1c2128",
    "panel.border": "#444c56",
    "panelInput.border": "#444c56",
    "panelTitle.activeBorder": "#ec775c",
    "panelTitle.activeForeground": "#adbac7",
    "panelTitle.inactiveForeground": "#768390",
    "peekViewEditor.background": "#636e7b1a",
    "peekViewEditor.matchHighlightBackground": "#ae7c1466",
    "peekViewResult.background": "#22272e",
    "peekViewResult.matchHighlightBackground": "#ae7c1466",
    "pickerGroup.border": "#444c56",
    "pickerGroup.foreground": "#768390",
    "progressBar.background": "#316dca",
    "quickInput.background": "#2d333b",
    "quickInput.foreground": "#adbac7",
    "scrollbar.shadow": "#545d6833",
    "scrollbarSlider.activeBackground": "#76839047",
    "scrollbarSlider.background": "#76839033",
    "scrollbarSlider.hoverBackground": "#7683903d",
    "settings.headerForeground": "#adbac7",
    "settings.modifiedItemIndicator": "#ae7c1466",
    "sideBar.background": "#1c2128",
    "sideBar.border": "#444c56",
    "sideBar.foreground": "#adbac7",
    "sideBarSectionHeader.background": "#1c2128",
    "sideBarSectionHeader.border": "#444c56",
    "sideBarSectionHeader.foreground": "#adbac7",
    "sideBarTitle.foreground": "#adbac7",
    "statusBar.background": "#22272e",
    "statusBar.border": "#444c56",
    "statusBar.debuggingBackground": "#c93c37",
    "statusBar.debuggingForeground": "#cdd9e5",
    "statusBar.focusBorder": "#316dca80",
    "statusBar.foreground": "#768390",
    "statusBar.noFolderBackground": "#22272e",
    "statusBarItem.activeBackground": "#adbac71f",
    "statusBarItem.focusBorder": "#316dca",
    "statusBarItem.hoverBackground": "#adbac714",
    "statusBarItem.prominentBackground": "#636e7b66",
    "statusBarItem.remoteBackground": "#444c56",
    "statusBarItem.remoteForeground": "#adbac7",
    "symbolIcon.arrayForeground": "#e0823d",
    "symbolIcon.booleanForeground": "#539bf5",
    "symbolIcon.classForeground": "#e0823d",
    "symbolIcon.colorForeground": "#6cb6ff",
    "symbolIcon.constantForeground": [
      "#b4f1b4",
      "#8ddb8c",
      "#6bc46d",
      "#57ab5a",
      "#46954a",
      "#347d39",
      "#2b6a30",
      "#245829",
      "#1b4721",
      "#113417"
    ],
    "symbolIcon.constructorForeground": "#dcbdfb",
    "symbolIcon.enumeratorForeground": "#e0823d",
    "symbolIcon.enumeratorMemberForeground": "#539bf5",
    "symbolIcon.eventForeground": "#636e7b",
    "symbolIcon.fieldForeground": "#e0823d",
    "symbolIcon.fileForeground": "#c69026",
    "symbolIcon.folderForeground": "#c69026",
    "symbolIcon.functionForeground": "#b083f0",
    "symbolIcon.interfaceForeground": "#e0823d",
    "symbolIcon.keyForeground": "#539bf5",
    "symbolIcon.keywordForeground": "#f47067",
    "symbolIcon.methodForeground": "#b083f0",
    "symbolIcon.moduleForeground": "#f47067",
    "symbolIcon.namespaceForeground": "#f47067",
    "symbolIcon.nullForeground": "#539bf5",
    "symbolIcon.numberForeground": "#57ab5a",
    "symbolIcon.objectForeground": "#e0823d",
    "symbolIcon.operatorForeground": "#6cb6ff",
    "symbolIcon.packageForeground": "#e0823d",
    "symbolIcon.propertyForeground": "#e0823d",
    "symbolIcon.referenceForeground": "#539bf5",
    "symbolIcon.snippetForeground": "#539bf5",
    "symbolIcon.stringForeground": "#6cb6ff",
    "symbolIcon.structForeground": "#e0823d",
    "symbolIcon.textForeground": "#6cb6ff",
    "symbolIcon.typeParameterForeground": "#6cb6ff",
    "symbolIcon.unitForeground": "#539bf5",
    "symbolIcon.variableForeground": "#e0823d",
    "tab.activeBackground": "#22272e",
    "tab.activeBorder": "#22272e",
    "tab.activeBorderTop": "#ec775c",
    "tab.activeForeground": "#adbac7",
    "tab.border": "#444c56",
    "tab.hoverBackground": "#22272e",
    "tab.inactiveBackground": "#1c2128",
    "tab.inactiveForeground": "#768390",
    "tab.unfocusedActiveBorder": "#22272e",
    "tab.unfocusedActiveBorderTop": "#444c56",
    "tab.unfocusedHoverBackground": "#636e7b1a",
    "terminal.ansiBlack": "#545d68",
    "terminal.ansiBlue": "#539bf5",
    "terminal.ansiBrightBlack": "#636e7b",
    "terminal.ansiBrightBlue": "#6cb6ff",
    "terminal.ansiBrightCyan": "#56d4dd",
    "terminal.ansiBrightGreen": "#6bc46d",
    "terminal.ansiBrightMagenta": "#dcbdfb",
    "terminal.ansiBrightRed": "#ff938a",
    "terminal.ansiBrightWhite": "#cdd9e5",
    "terminal.ansiBrightYellow": "#daaa3f",
    "terminal.ansiCyan": "#39c5cf",
    "terminal.ansiGreen": "#57ab5a",
    "terminal.ansiMagenta": "#b083f0",
    "terminal.ansiRed": "#f47067",
    "terminal.ansiWhite": "#909dab",
    "terminal.ansiYellow": "#c69026",
    "terminal.foreground": "#adbac7",
    "textBlockQuote.background": "#1c2128",
    "textBlockQuote.border": "#444c56",
    "textCodeBlock.background": "#636e7b66",
    "textLink.activeForeground": "#539bf5",
    "textLink.foreground": "#539bf5",
    "textPreformat.foreground": "#768390",
    "textSeparator.foreground": "#373e47",
    "titleBar.activeBackground": "#22272e",
    "titleBar.activeForeground": "#768390",
    "titleBar.border": "#444c56",
    "titleBar.inactiveBackground": "#1c2128",
    "titleBar.inactiveForeground": "#768390",
    "tree.indentGuidesStroke": "#373e47",
    "welcomePage.buttonBackground": "#373e47",
    "welcomePage.buttonHoverBackground": "#444c56"
  },
  "displayName": "GitHub Dark Dimmed",
  "name": "github-dark-dimmed",
  "semanticHighlighting": true,
  "tokenColors": [
    {
      "scope": [
        "comment",
        "punctuation.definition.comment",
        "string.comment"
      ],
      "settings": {
        "foreground": "#768390"
      }
    },
    {
      "scope": [
        "constant.other.placeholder",
        "constant.character"
      ],
      "settings": {
        "foreground": "#f47067"
      }
    },
    {
      "scope": [
        "constant",
        "entity.name.constant",
        "variable.other.constant",
        "variable.other.enummember",
        "variable.language",
        "entity"
      ],
      "settings": {
        "foreground": "#6cb6ff"
      }
    },
    {
      "scope": [
        "entity.name",
        "meta.export.default",
        "meta.definition.variable"
      ],
      "settings": {
        "foreground": "#f69d50"
      }
    },
    {
      "scope": [
        "variable.parameter.function",
        "meta.jsx.children",
        "meta.block",
        "meta.tag.attributes",
        "entity.name.constant",
        "meta.object.member",
        "meta.embedded.expression"
      ],
      "settings": {
        "foreground": "#adbac7"
      }
    },
    {
      "scope": "entity.name.function",
      "settings": {
        "foreground": "#dcbdfb"
      }
    },
    {
      "scope": [
        "entity.name.tag",
        "support.class.component"
      ],
      "settings": {
        "foreground": "#8ddb8c"
      }
    },
    {
      "scope": "keyword",
      "settings": {
        "foreground": "#f47067"
      }
    },
    {
      "scope": [
        "storage",
        "storage.type"
      ],
      "settings": {
        "foreground": "#f47067"
      }
    },
    {
      "scope": [
        "storage.modifier.package",
        "storage.modifier.import",
        "storage.type.java"
      ],
      "settings": {
        "foreground": "#adbac7"
      }
    },
    {
      "scope": [
        "string",
        "string punctuation.section.embedded source"
      ],
      "settings": {
        "foreground": "#96d0ff"
      }
    },
    {
      "scope": "support",
      "settings": {
        "foreground": "#6cb6ff"
      }
    },
    {
      "scope": "meta.property-name",
      "settings": {
        "foreground": "#6cb6ff"
      }
    },
    {
      "scope": "variable",
      "settings": {
        "foreground": "#f69d50"
      }
    },
    {
      "scope": "variable.other",
      "settings": {
        "foreground": "#adbac7"
      }
    },
    {
      "scope": "invalid.broken",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ff938a"
      }
    },
    {
      "scope": "invalid.deprecated",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ff938a"
      }
    },
    {
      "scope": "invalid.illegal",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ff938a"
      }
    },
    {
      "scope": "invalid.unimplemented",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#ff938a"
      }
    },
    {
      "scope": "carriage-return",
      "settings": {
        "background": "#f47067",
        "content": "^M",
        "fontStyle": "italic underline",
        "foreground": "#cdd9e5"
      }
    },
    {
      "scope": "message.error",
      "settings": {
        "foreground": "#ff938a"
      }
    },
    {
      "scope": "string variable",
      "settings": {
        "foreground": "#6cb6ff"
      }
    },
    {
      "scope": [
        "source.regexp",
        "string.regexp"
      ],
      "settings": {
        "foreground": "#96d0ff"
      }
    },
    {
      "scope": [
        "string.regexp.character-class",
        "string.regexp constant.character.escape",
        "string.regexp source.ruby.embedded",
        "string.regexp string.regexp.arbitrary-repitition"
      ],
      "settings": {
        "foreground": "#96d0ff"
      }
    },
    {
      "scope": "string.regexp constant.character.escape",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#8ddb8c"
      }
    },
    {
      "scope": "support.constant",
      "settings": {
        "foreground": "#6cb6ff"
      }
    },
    {
      "scope": "support.variable",
      "settings": {
        "foreground": "#6cb6ff"
      }
    },
    {
      "scope": "support.type.property-name.json",
      "settings": {
        "foreground": "#8ddb8c"
      }
    },
    {
      "scope": "meta.module-reference",
      "settings": {
        "foreground": "#6cb6ff"
      }
    },
    {
      "scope": "punctuation.definition.list.begin.markdown",
      "settings": {
        "foreground": "#f69d50"
      }
    },
    {
      "scope": [
        "markup.heading",
        "markup.heading entity.name"
      ],
      "settings": {
        "fontStyle": "bold",
        "foreground": "#6cb6ff"
      }
    },
    {
      "scope": "markup.quote",
      "settings": {
        "foreground": "#8ddb8c"
      }
    },
    {
      "scope": "markup.italic",
      "settings": {
        "fontStyle": "italic",
        "foreground": "#adbac7"
      }
    },
    {
      "scope": "markup.bold",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#adbac7"
      }
    },
    {
      "scope": [
        "markup.underline"
      ],
      "settings": {
        "fontStyle": "underline"
      }
    },
    {
      "scope": [
        "markup.strikethrough"
      ],
      "settings": {
        "fontStyle": "strikethrough"
      }
    },
    {
      "scope": "markup.inline.raw",
      "settings": {
        "foreground": "#6cb6ff"
      }
    },
    {
      "scope": [
        "markup.deleted",
        "meta.diff.header.from-file",
        "punctuation.definition.deleted"
      ],
      "settings": {
        "background": "#5d0f12",
        "foreground": "#ff938a"
      }
    },
    {
      "scope": [
        "punctuation.section.embedded"
      ],
      "settings": {
        "foreground": "#f47067"
      }
    },
    {
      "scope": [
        "markup.inserted",
        "meta.diff.header.to-file",
        "punctuation.definition.inserted"
      ],
      "settings": {
        "background": "#113417",
        "foreground": "#8ddb8c"
      }
    },
    {
      "scope": [
        "markup.changed",
        "punctuation.definition.changed"
      ],
      "settings": {
        "background": "#682d0f",
        "foreground": "#f69d50"
      }
    },
    {
      "scope": [
        "markup.ignored",
        "markup.untracked"
      ],
      "settings": {
        "background": "#6cb6ff",
        "foreground": "#2d333b"
      }
    },
    {
      "scope": "meta.diff.range",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#dcbdfb"
      }
    },
    {
      "scope": "meta.diff.header",
      "settings": {
        "foreground": "#6cb6ff"
      }
    },
    {
      "scope": "meta.separator",
      "settings": {
        "fontStyle": "bold",
        "foreground": "#6cb6ff"
      }
    },
    {
      "scope": "meta.output",
      "settings": {
        "foreground": "#6cb6ff"
      }
    },
    {
      "scope": [
        "brackethighlighter.tag",
        "brackethighlighter.curly",
        "brackethighlighter.round",
        "brackethighlighter.square",
        "brackethighlighter.angle",
        "brackethighlighter.quote"
      ],
      "settings": {
        "foreground": "#768390"
      }
    },
    {
      "scope": "brackethighlighter.unmatched",
      "settings": {
        "foreground": "#ff938a"
      }
    },
    {
      "scope": [
        "constant.other.reference.link",
        "string.other.link"
      ],
      "settings": {
        "foreground": "#96d0ff"
      }
    }
  ],
  "type": "dark"
});

export { githubDarkDimmed as default };
